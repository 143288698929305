import { Button, Flex, Stack, Tag, TagLabel, TagLeftIcon } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { CargoType, DocumentType } from '@pelicargo/types';
import { Show, useError } from '@pelicargo/ui';
import { removeDash } from '@pelicargo/utils';
import { FocusEventHandler, useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { HiOutlineExclamation } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';

import { trpc } from '../../config/trpc';
import { formatAwbNumber } from '../../utils/inputFormatters';
import { SimpleCheckbox } from '../SimpleCheckbox';
import { SimpleDatePickerInput } from '../SimpleDatePickerInput';
import { SimpleDropzone } from '../SimpleDropzone';
import { SimpleForm } from '../SimpleForm';
import { SimpleInput } from '../SimpleInput';
import { SimpleTextarea } from '../SimpleTextarea';

type FormValues = {
  awb_number: string;
  tender_date: string;
  tender_date_time_of_day: string;
  customer_ref: string;
  additional_information: string;
  terms: boolean;
  documents: any[];
};

const schema = Yup.object({
  terms: Yup.boolean().oneOf([true], 'Please accept the terms and conditions'),
  awb_number: Yup.string()
    .nullable()
    .test('awb-number-length', 'AWB number must be exactly 11 characters', (value) => {
      const cleanedValue = removeDash(value);
      // Check if the field is empty or has only the pre-filled digits
      if (!cleanedValue || cleanedValue?.length === 3) return true;
      // Check if the length is exactly 11 characters
      return cleanedValue?.length === 11;
    }),
  tender_date: Yup.date().required('Tender date is required'),
  customer_ref: Yup.string().optional(),
  additional_information: Yup.string(),
  documents: Yup.array().when('cargoType', {
    is: CargoType.DangerousGoods,
    then: () => Yup.array().min(1, 'Please upload a document'),
  }),
});

type Props = {
  requestId: number;
  quoteId: number;
  customer_ref?: string;
  awb_number?: string;
  documents?: any[];
  cargo?: any[];
  origin_airports?: any[];
  quoteVariant?: any;
  awbPrefix?: string;
  airlineId?: string;
};

export const BookingForm = ({
  requestId,
  quoteId,
  customer_ref,
  awb_number,
  documents,
  cargo,
  quoteVariant,
  awbPrefix,
  airlineId,
}: Props) => {
  const navigate = useNavigate();
  const { handleError } = useError();

  const bookQuote = trpc.bookQuote.useMutation();

  const methods = useForm<FormValues>({
    mode: 'onSubmit',
    resolver: yupResolver(schema) as any,
  });

  const documentsInForm = methods.watch('documents');

  useEffect(() => {
    methods.reset({
      ...methods.getValues(),
      customer_ref: customer_ref ?? '',
      awb_number: awb_number ?? '',
      documents: documents ?? [],
    });
  }, [methods, customer_ref, awb_number, documents]);

  const shouldShowDgdWarning = useMemo(() => {
    const isDgd = cargo?.[0]?.type === CargoType.DangerousGoods;
    const hasDocument = documentsInForm?.length > 0;

    if (isDgd && !hasDocument) return true;
    return false;
  }, [documentsInForm, cargo]);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      try {
        const normalizedAwb = values.awb_number?.length > 3 ? removeDash(values.awb_number) : null;

        if (!quoteVariant) {
          handleError('Please select a quote variant.');
          return;
        }

        await bookQuote.mutateAsync({
          id: quoteId,
          requestId,
          awbNumber: normalizedAwb,
          customerRef: values.customer_ref,
          additionalInformation: values.additional_information,
          tenderDate: values.tender_date as any,
          selectedService: quoteVariant?.type,
          tenderDateTimeOfDay: values.tender_date_time_of_day as any,
        });

        navigate(`/requests/${requestId}/quotes/${quoteId}/booked`);
      } catch (error) {
        handleError(error);
      }
    },
    [bookQuote, handleError, navigate, quoteId, quoteVariant, requestId],
  );

  const [awbWarning, setAwbWarning] = useState('');

  const checkAWB: FocusEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      const input = event?.target?.value;
      const hasAwbPrefix = !!awbPrefix;
      const hasInvalidPrefix = input?.length >= 3 && input?.slice(0, 3) !== awbPrefix;

      if (hasInvalidPrefix && hasAwbPrefix) {
        setAwbWarning(`Expecting AWB for ${airlineId} to start with ${awbPrefix}. Are you sure this is correct?`);
      } else setAwbWarning('');
    },
    [airlineId, awbPrefix],
  );

  return (
    <SimpleForm onSubmit={handleSubmit} {...(methods as any)}>
      <Stack w="full" spacing="3">
        <Stack direction={{ base: 'column', lg: 'row' }} w="full">
          <SimpleInput
            label="AWB #"
            name="awb_number"
            placeholder="x x x | x x x x x x x x"
            maxLength={12}
            variant="outline"
            w="full"
            formatter={formatAwbNumber}
            onBlur={checkAWB}
            errorMessage={awbWarning}
          />
          <SimpleDatePickerInput
            name="tender_date"
            label="Tender Date *"
            variant="outline"
            matchWidth={false}
            timeOfDay
            timeOfDayName="tender_date_time_of_day"
            iconFontSize="20px"
            w="full"
          />
          <SimpleInput
            label="Your Reference #"
            name="customer_ref"
            placeholder="x x x   x x x   x x x"
            variant="outline"
            w="full"
          />
        </Stack>
        <SimpleTextarea
          name="additional_information"
          label="Additional Notes"
          placeholder="Additional notes"
          variant="outline"
          rows={2}
        />
        <Show if={shouldShowDgdWarning}>
          <Flex>
            <Tag size="lg" borderRadius="full" variant="outline" colorScheme="red" py="2">
              <TagLeftIcon as={HiOutlineExclamation} boxSize="6" />
              <TagLabel fontSize="lg">Missing Dangerous Goods Declaration - Upload to Book</TagLabel>
            </Tag>
          </Flex>
        </Show>
        <Show if={cargo?.[0]?.type === CargoType.DangerousGoods}>
          <SimpleDropzone name="documents" documentType={DocumentType.DGD} requestId={requestId} />
        </Show>
        <SimpleCheckbox
          name="terms"
          label="By checking, I accept the airline's conditions of contract"
          variant="unstyled"
        />
        <Button
          colorScheme="primary"
          size="lg"
          type="submit"
          isLoading={bookQuote.isLoading}
          isDisabled={shouldShowDgdWarning}
        >
          Request Booking
        </Button>
      </Stack>
    </SimpleForm>
  );
};
