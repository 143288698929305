import { Button, Flex, Icon, IconButton, Stack, StackProps, useColorModeValue } from '@chakra-ui/react';
import { HandlingOptions } from '@pelicargo/types';
import { ReactElement } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { HiOutlinePlusCircle, HiTrash } from 'react-icons/hi2';

interface SimpleFieldArrayForm {
  prefix: string;
  isFirst: boolean;
  isLast: boolean;
  hasMultiple: boolean;
}

type SimpleFieldArrayProps = StackProps & {
  name: string;
  form: (props: SimpleFieldArrayForm) => ReactElement;
  onRemove?: (values: any) => void;
  ignoreStyles?: boolean;
  isFormInModal?: boolean;
  addButtonLabel?: string;
};

export const SimpleFieldArray = ({
  name,
  form,
  onRemove,
  ignoreStyles = false,
  isFormInModal = false,
  addButtonLabel = 'Add Item',
  ...rest
}: SimpleFieldArrayProps) => {
  const { control } = useFormContext();
  const { append, fields, remove } = useFieldArray({
    control,
    name,
  });

  const borderColor = useColorModeValue('gray.100', 'gray.700');
  const hasMultiple = fields.length > 1;

  const handleAddItem = () => {
    append({
      quantity: 1,
      weight: undefined,
      height: undefined,
      length: undefined,
      width: undefined,
      handling: HandlingOptions.Stackable,
      packing: [],
    });
  };

  const handleRemove = (index: number) => () => {
    onRemove?.(index);
    remove(index);
  };

  return (
    <Stack direction="column" spacing={ignoreStyles ? 0 : 8} width="full" {...rest}>
      {fields.map((field, index) => {
        const isFirst = index === 0;
        const isLast = index === fields.length - 1;

        return (
          <Stack
            direction={{ base: 'column', lg: 'row' }}
            position="relative"
            key={field.id}
            pt={ignoreStyles ? 0 : isFirst ? 0 : 8}
            borderTop={ignoreStyles ? 0 : isFirst ? '' : '1px solid'}
            alignItems="center"
            borderTopColor={ignoreStyles ? null : borderColor}
          >
            <Stack spacing={ignoreStyles ? 0 : 8} direction="column" width="full">
              {form({
                prefix: `${name}.${index}`,
                isFirst,
                isLast,
                hasMultiple,
              })}
            </Stack>
            <IconButton
              position={{
                base: 'static',
                md: isFormInModal ? 'initial' : 'absolute',
              }}
              right={isFormInModal ? 'initial' : -12}
              size="sm"
              aria-label="Remove Row"
              variant="ghost"
              colorScheme="red"
              icon={<Icon as={HiTrash} fontSize={16} />}
              onClick={handleRemove(index)}
            />
          </Stack>
        );
      })}

      <Flex>
        <Button
          variant="link"
          textTransform="uppercase"
          fontWeight="500"
          color="offBlack"
          leftIcon={<HiOutlinePlusCircle />}
          onClick={handleAddItem}
          size="lg"
        >
          {addButtonLabel}
        </Button>
      </Flex>
    </Stack>
  );
};
