import { Button, ButtonGroup, Card, CardBody, Grid, GridItem, Heading, Icon, Stack, Text } from '@chakra-ui/react';
import { BatteryType, CargoType, DocumentType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import {
  aircraftVariantOptions,
  batteryTypeVariantOptions,
  getPrettyCargoType,
  packingGroupOptions,
  packingInstructionOptions,
  sectionOptions,
} from '@pelicargo/utils';
import { useFormContext, useWatch } from 'react-hook-form';
import { HiExclamationCircle } from 'react-icons/hi2';

import { DGDTab } from '../../hooks/request/useForm';
import { Hint } from '../Hint';
import { SimpleDropzone } from '../SimpleDropzone';
import { SimpleNumberInput } from '../SimpleNumberInput';
import { SimpleRadio } from '../SimpleRadio';
import { SimpleSelect } from '../SimpleSelect';
import { SimpleSwitch } from '../SimpleSwitch';

export const DangerousGoodsForm = () => {
  const { setValue } = useFormContext();
  const dgdTab: DGDTab = useWatch({ name: 'dgdTab' });
  const cargoType: CargoType = useWatch({ name: 'cargo_type' });
  const batteryType: BatteryType = useWatch({ name: 'battery_type' });

  const handleTabChange = (nextTab: DGDTab) => () => {
    setValue('dgdTab', nextTab);
  };

  return (
    <Hint name="home-dangerousGoodsForm">
      <Card>
        <CardBody>
          <Stack spacing="4">
            <Stack
              direction={{ base: 'column', lg: 'row' }}
              align={{ base: 'flex-start', lg: 'center' }}
              justify="space-between"
              wrap="wrap"
            >
              <Show if={cargoType === CargoType.DangerousGoods || (cargoType === CargoType.Batteries && !!batteryType)}>
                <Heading size="md">{getPrettyCargoType(cargoType)}</Heading>
              </Show>
              <Show
                if={
                  // Batteries isn't actually a part of CargoType but it's part of the UI
                  cargoType === CargoType.DangerousGoods ||
                  (cargoType === CargoType.Batteries && batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT)
                }
              >
                <ButtonGroup>
                  <Button colorScheme={dgdTab === DGDTab.DGD ? 'slate' : 'gray'} onClick={handleTabChange(DGDTab.DGD)}>
                    Upload DGD
                  </Button>
                  <Button
                    colorScheme={dgdTab === DGDTab.MSDS ? 'slate' : 'gray'}
                    onClick={handleTabChange(DGDTab.MSDS)}
                  >
                    Upload MSDS/Manual Input
                  </Button>
                </ButtonGroup>
              </Show>
            </Stack>

            <Show if={cargoType === CargoType.Batteries && !batteryType}>
              <Stack direction="row" align="center" justify="center" py="8">
                <Icon as={HiExclamationCircle} boxSize="6" color="red.500" />
                <Text fontSize="xl">Please select a battery type.</Text>
              </Stack>
            </Show>

            <Show
              if={
                (dgdTab === DGDTab.MSDS && cargoType === CargoType.DangerousGoods) ||
                (dgdTab === DGDTab.MSDS &&
                  cargoType === CargoType.Batteries &&
                  batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT)
              }
            >
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  lg: 'repeat(12, 1fr)',
                }}
                gap={4}
                alignItems="baseline"
              >
                <GridItem colSpan={{ base: 1, lg: 3 }} alignItems="baseline">
                  <Show if={cargoType === CargoType.DangerousGoods}>
                    <SimpleNumberInput
                      name="un_number"
                      label="UN #"
                      placeholder="4-digit #"
                      allowDecimals={false}
                      maxLength={4}
                    />
                  </Show>
                  <Show if={batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT}>
                    <SimpleSelect
                      name="un_number"
                      label="UN #"
                      options={[
                        { label: '3480', value: 3480 },
                        { label: '3481', value: 3481 },
                        { label: '3090', value: 3090 },
                        { label: '3091', value: 3091 },
                        { label: '3171', value: 3171 },
                      ]}
                    />
                  </Show>
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 3 }} alignItems="baseline">
                  <Show if={cargoType === CargoType.DangerousGoods}>
                    <SimpleNumberInput
                      name="class"
                      label="Class"
                      placeholder="2-digit dec. e.g 1.1"
                      maxLength={2}
                      allowDecimals
                    />
                  </Show>
                  <Show if={batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT}>
                    <SimpleSelect
                      label="Packing Instruction"
                      name="packing_instruction"
                      options={packingInstructionOptions}
                    />
                  </Show>
                </GridItem>
                <GridItem colSpan={{ base: 1, lg: 6 }} alignItems="baseline">
                  <Grid
                    templateColumns={{
                      base: 'repeat(2, 1fr)',
                    }}
                    gap={4}
                  >
                    <GridItem colSpan={1}>
                      <Show if={cargoType === CargoType.DangerousGoods}>
                        <SimpleSwitch
                          name="packing_group"
                          variant="outline"
                          label="Packing Group"
                          options={packingGroupOptions}
                        />
                      </Show>
                      <Show if={batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT}>
                        <SimpleSwitch name="section" label="Section" variant="outline" options={sectionOptions} />
                      </Show>
                    </GridItem>
                    <GridItem colSpan={1}>
                      <SimpleSwitch
                        name="aircraft_variant"
                        label="Aircraft"
                        variant="outline"
                        options={aircraftVariantOptions}
                      />
                    </GridItem>
                  </Grid>
                </GridItem>
              </Grid>
            </Show>
            <Show if={batteryType === BatteryType.NON_RESTRICTED_BATTERIES_IN_EQUIPMENT}>
              <SimpleRadio name="battery_type_variant" variant="unstyled" options={batteryTypeVariantOptions} />
            </Show>

            <Show
              if={
                cargoType === CargoType.DangerousGoods ||
                (cargoType === CargoType.Batteries && batteryType === BatteryType.DG_BATTERIES_IN_EQUIPMENT)
              }
            >
              <SimpleDropzone
                name="documents"
                documentType={dgdTab === DGDTab.DGD ? DocumentType.DGD : DocumentType.MSDS}
              />
            </Show>
          </Stack>
        </CardBody>
      </Card>
    </Hint>
  );
};
