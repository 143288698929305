import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Divider,
  GridItem,
  Image,
  SimpleGrid,
  Skeleton,
  SkeletonText,
  Stack,
  Tag,
  Text,
  useBreakpointValue,
  useDisclosure,
} from '@chakra-ui/react';
import { AircraftType, LineItemType, QuoteStage, QuoteStatus, ServiceType } from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { exclusiveVariableRate, prettyCurrency, prettyNumber, screeningFee } from '@pelicargo/utils';
import { memo, useEffect, useMemo, useState } from 'react';
import { HiChevronRight } from 'react-icons/hi2';
import { useNavigate } from 'react-router-dom';

import { API } from '../../utils/api-typings';
import { getLineItemsTotal } from '../../utils/getLineItemTotal';
import { FlightPath } from '../FlightPath';
import { FlightSegmentsCard } from '../FlightSegmentsCard';
import { Hint } from '../Hint';
import { PriceCardTabs } from '../PriceCardTabs';

type Props = {
  quote?: API.RequestQuote & {
    is_cheapest: boolean;
    is_fastest: boolean;
  };
  selectedServiceType: ServiceType;
  isShowingHints?: boolean;
};

export const QuoteListItem = memo(({ quote, selectedServiceType, isShowingHints }: Props) => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  useEffect(() => {
    const nextIndex = quote?.quote_variants?.findIndex((variant) => variant.type === selectedServiceType);
    if (nextIndex !== -1) setTabIndex(nextIndex);
  }, [quote?.quote_variants, selectedServiceType]);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    const found = quote?.quote_variants?.findIndex((variant) => variant.type === selectedServiceType);

    if (found !== -1) setTabIndex(found);
  }, [quote?.quote_variants, selectedServiceType]);

  const trimmedFlightSegments = useMemo(() => {
    const segments = [];

    quote?.flight_path?.forEach((node) => {
      segments.push({
        origin: node?.origin_airport_id,
        destination: node?.destination_airport_id,
        aircraftType: node?.aircraft_type,
      });
    });

    return segments;
  }, [quote?.flight_path]);

  const otherFlightSegments = useMemo(() => {
    if (!quote?.flight_path || quote.flight_path.length === 0) return [];

    const { flight_path } = quote;
    const segments: string[] = [];
    const pathLength = flight_path.length;

    // Always add the origin airport of the first segment
    segments.push(flight_path[0].origin_airport_id);

    // Iterate over all nodes to add airports, avoiding duplicates
    for (let i = 0; i < pathLength; i++) {
      const currentDestination = flight_path[i].destination_airport_id;
      segments.push(currentDestination);

      if (i < pathLength - 1) {
        const nextOrigin = flight_path[i + 1].origin_airport_id;
        // Only add the next origin if it's not the same as the current destination
        if (currentDestination !== nextOrigin) segments.push(nextOrigin);
      }
    }

    return segments;
  }, [quote]);

  const stopCount = useMemo(() => trimmedFlightSegments?.length - 1, [trimmedFlightSegments]);

  const stopCountLabel = useMemo(() => {
    if (stopCount === 0) return 'Nonstop';
    if (stopCount === 1) return '1 Stop';
    return `${stopCount} Stops`;
  }, [stopCount]);

  const isExpired = useMemo(() => quote?.status === QuoteStatus.Expired, [quote?.status]);

  const isSpotRate = useMemo(() => {
    return quote?.quote_variants?.[tabIndex]?.line_items?.some((item) => item.type === LineItemType.SPOT);
  }, [quote?.quote_variants, tabIndex]);

  const screeningRate = useMemo(() => {
    return screeningFee((quote?.quote_variants?.[tabIndex]?.line_items as any[]) || []);
  }, [quote?.quote_variants, tabIndex]);

  const isScreeningRatePerUnit = useMemo(() => {
    return quote?.quote_variants?.[tabIndex]?.line_items?.some(
      (item) => item.type === LineItemType.SCREENING && !!item.per_unit_value,
    );
  }, [quote?.quote_variants, tabIndex]);

  const total = useMemo(() => {
    const lineItemsTotal = getLineItemsTotal(quote?.quote_variants?.[tabIndex]?.line_items || []);
    return lineItemsTotal;
  }, [quote?.quote_variants, tabIndex]);

  const exclusiveVariableRateCalc = useMemo(() => {
    const rate = exclusiveVariableRate(
      (quote?.quote_variants?.[tabIndex]?.line_items as any[]) || [],
      quote?.chargeable_weight,
    );
    return prettyCurrency(rate);
  }, [quote?.quote_variants, quote?.chargeable_weight, tabIndex]);

  const mostConstrainingAircraftType = useMemo(() => {
    const order = [AircraftType.NarrowBody, AircraftType.WideBody, AircraftType.Freighter, AircraftType.RFS];
    const aircraftTypes = quote?.flight_path?.map((node) => node?.aircraft_type);

    const mostConstraining = aircraftTypes?.reduce((acc: AircraftType, type: AircraftType) => {
      if (order.indexOf(type) > order.indexOf(acc)) {
        return type;
      }
      return acc;
    }, order[0]);

    return mostConstraining;
  }, [quote?.flight_path]);

  const isQuoteEnabled = useMemo(() => {
    return [QuoteStage.TWO, QuoteStage.THREE, QuoteStage.FOUR].includes(quote?.stage);
  }, [quote?.stage]);

  const getConditionalOpacity = (condition: boolean) => (condition ? 1 : 0);

  const isPivotWeight = useMemo(() => {
    return quote?.chargeable_weight !== quote?.request?.chargeable_weight;
  }, [quote?.chargeable_weight, quote?.request?.chargeable_weight]);

  const isVerticalDividerVisible = useBreakpointValue({
    base: !isQuoteEnabled,
    sm: true,
  });

  const isFlightInfoVisible = useBreakpointValue({ base: false, sm: true });

  const isCardLink = useBreakpointValue({ base: true, sm: false });

  const handleCardClick = () => {
    if (isQuoteEnabled) onToggle();

    if (isCardLink) {
      navigate(`/requests/${quote.request_id}/quotes/${quote.id}`, {
        state: { quoteVariantIndex: tabIndex },
      });
    }
  };

  return (
    <Card
      p="0"
      borderRadius="lg"
      transition="border-color 200ms ease"
      overflow="hidden"
      borderColor="border.default"
      _hover={{
        borderWidth: '1px',
        borderColor: isQuoteEnabled ? 'border.hover' : 'border.default',
        overflow: 'hidden',
      }}
    >
      <CardBody p="0" m={0} borderRadius="lg" cursor={isQuoteEnabled ? 'pointer' : 'initial'} onClick={handleCardClick}>
        <Stack
          direction={{ base: 'column', sm: 'row' }}
          spacing="0"
          p={0}
          h="full"
          divider={!isVerticalDividerVisible && <Divider />}
        >
          <Stack
            spacing="1.5"
            flex="1"
            pt="0"
            pb="2"
            px="4"
            borderTopLeftRadius="lg"
            borderBottomLeftRadius="lg"
            justify="space-evenly"
          >
            <Stack direction="row" align="center" pt={!quote?.is_cheapest && !quote?.is_fastest ? 6 : 0}>
              <Show if={quote?.is_cheapest}>
                <Tag colorScheme="orange" variant="subtle" fontWeight="500" size="md" borderTopRadius={0}>
                  Cheapest
                </Tag>
              </Show>
              <Show if={quote?.is_fastest}>
                <Tag colorScheme="green" variant="subtle" fontWeight="500" size="md" borderTopRadius={0}>
                  Fastest
                </Tag>
              </Show>
            </Stack>
            <Stack
              direction="row"
              justify={{ base: 'space-between', md: 'left' }}
              align="center"
              wrap="wrap"
              spacing={{ base: 6, lg: 12 }}
            >
              <Skeleton isLoaded={!!quote?.airline}>
                <Image src={quote?.airline?.logo_uri} height="40px" width="40px" alt={quote?.airline?.name} />
              </Skeleton>

              <Show if={otherFlightSegments?.length > 1}>
                <Stack direction="row" maxW="300px">
                  <FlightPath flightSegments={otherFlightSegments} />
                </Stack>
              </Show>

              <Show if={!isOpen && isFlightInfoVisible}>
                <SimpleGrid columns={3} columnGap={4}>
                  <GridItem colSpan={1}>
                    <Text textStyle="bodySmallRegular" minW="20">
                      {stopCountLabel}
                    </Text>
                  </GridItem>
                  <Show if={!!quote?.estimated_transit_time}>
                    <GridItem colSpan={1}>
                      <Text textStyle="bodySmallRegular" minW="20">
                        {quote?.estimated_transit_time}
                      </Text>
                    </GridItem>
                  </Show>
                  <GridItem colSpan={1}>
                    <Text textStyle="bodySmallRegular" minW="20">
                      {mostConstrainingAircraftType}
                    </Text>
                  </GridItem>
                </SimpleGrid>
              </Show>
            </Stack>
            <Stack direction="row" align="center">
              <SkeletonText isLoaded={!!quote?.airline} noOfLines={1} w={!quote?.airline ? '20' : 'full'}>
                <Text textStyle="captionSmallRegular" color="text.subtle">
                  {quote?.airline?.name}
                </Text>
              </SkeletonText>
            </Stack>
          </Stack>
          <Show if={isVerticalDividerVisible}>
            <Stack py="2">
              <Divider orientation="vertical" />
            </Stack>
          </Show>
          <Stack
            justify="space-between"
            spacing="1.5"
            px="4"
            pb="2"
            minW="240px"
            backgroundColor="bg.surface !important"
            _hover={{
              backgroundColor: 'bg.surface !important',
            }}
          >
            <Show if={isQuoteEnabled}>
              <Stack direction="row" justify="flex-end">
                <Tag
                  variant="subtle"
                  colorScheme="purple"
                  fontWeight="500"
                  size="md"
                  borderTopRadius={0}
                  opacity={getConditionalOpacity(isPivotWeight)}
                >
                  {`${prettyNumber(quote?.chargeable_weight)}kg - Pivot Weight`}
                </Tag>
                <Tag
                  variant="subtle"
                  colorScheme="blue"
                  fontWeight="500"
                  size="md"
                  borderTopRadius={0}
                  opacity={getConditionalOpacity(isSpotRate)}
                >
                  Deal
                </Tag>
              </Stack>
              <Stack direction="row" align="center" justify="flex-end" spacing={4}>
                <Text textStyle="bodySmallMedium" color="text.subtle">
                  {prettyCurrency(total)}
                </Text>
                <Hint name="quoteListing-quote-cta" placement="auto" isDisplaying={isShowingHints}>
                  <Button
                    colorScheme="primary"
                    size="sm"
                    iconSpacing="2"
                    minW="113px"
                    onClick={() =>
                      navigate(`/requests/${quote.request_id}/quotes/${quote.id}`, {
                        state: { quoteVariantIndex: tabIndex },
                      })
                    }
                    rightIcon={<HiChevronRight strokeWidth="2" />}
                  >
                    <Text textStyle="bodySemibold" color="white">
                      {exclusiveVariableRateCalc}/kg
                    </Text>
                  </Button>
                </Hint>
              </Stack>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="row" spacing={1}>
                  <Show if={quote?.backlog_status === 'AtRisk'}>
                    <Text textStyle="captionSmallMedium" color="error">
                      Backlog risk
                    </Text>
                  </Show>
                  <Show if={quote?.backlog_status === 'AtRisk' && isExpired}>
                    <Text textStyle="captionSmallMedium" color="error">
                      •
                    </Text>
                  </Show>
                  <Show if={isExpired}>
                    <Text textStyle="captionSmallMedium" color="error">
                      Expired
                    </Text>
                  </Show>
                </Stack>
                <Text
                  textStyle="captionSmallRegular"
                  color="gray.600"
                  opacity={screeningRate ? 1 : 0}
                >{`+${prettyCurrency(screeningRate)}${isScreeningRatePerUnit ? '/kg' : ''}`}</Text>
              </Stack>
            </Show>
          </Stack>
        </Stack>
      </CardBody>
      <Show if={isOpen}>
        <CardFooter
          bg="bg.subtle"
          display={{ base: 'none', md: 'flex' }}
          px="4"
          py="3"
          borderBottomStartRadius="lg"
          borderBottomEndRadius="lg"
        >
          <Stack
            direction={{ base: 'column', xl: 'row' }}
            align="center"
            justify="flex-start"
            w="full"
            p="0"
            spacing="3"
          >
            <FlightSegmentsCard
              flightSegments={trimmedFlightSegments}
              estimatedTransitTime={quote?.estimated_transit_time}
              h="full"
            />
            <PriceCardTabs
              tabIndex={tabIndex}
              onTabChange={setTabIndex}
              quoteVariants={quote?.quote_variants}
              cw={quote?.chargeable_weight}
              expiresAt={quote?.expires_at}
              showBookQuoteButton
            />
          </Stack>
        </CardFooter>
      </Show>
    </Card>
  );
});
