import {
  Box,
  BoxProps,
  Circle,
  Icon,
  Stack,
  StackProps,
  useId,
  useRadio,
  useRadioGroup,
  UseRadioProps,
  useStyleConfig,
} from '@chakra-ui/react';
import { Children, cloneElement, isValidElement, ReactElement, useMemo } from 'react';
import { HiCheck } from 'react-icons/hi';

interface RadioCardGroupProps<T> extends Omit<StackProps, 'onChange'> {
  name?: string;
  value?: T;
  defaultValue?: string;
  onChange?: (value: T) => void;
}

export const RadioCardGroup = <T extends string>(props: RadioCardGroupProps<T>) => {
  const { children, name, defaultValue, value, onChange, ...rest } = props;
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    defaultValue,
    value,
    onChange,
  });

  const cards = useMemo(
    () =>
      Children.toArray(children)
        .filter<ReactElement<RadioCardProps>>(isValidElement)
        .map((card) => {
          return cloneElement(card, {
            radioProps: getRadioProps({
              value: card.props.value,
            }),
          });
        }),
    [children, getRadioProps],
  );

  return <Stack {...getRootProps(rest)}>{cards}</Stack>;
};

interface RadioCardProps extends BoxProps {
  value: string;
  radioProps?: UseRadioProps;
}

export const RadioCard = (props: RadioCardProps) => {
  const { radioProps, children, ...rest } = props;
  const { getInputProps, getCheckboxProps, getLabelProps, state } = useRadio(radioProps);
  const id = useId(undefined, 'radio-button');

  const styles = useStyleConfig('RadioCard', props);
  const inputProps = getInputProps();
  const checkboxProps = getCheckboxProps();
  const labelProps = getLabelProps();
  return (
    <Box
      as="label"
      cursor="pointer"
      {...labelProps}
      borderWidth="1px"
      borderRadius="lg"
      _focus={{
        borderWidth: '1px',
        borderColor: 'gray.800',
      }}
      _focusWithin={{
        borderWidth: '1px',
        borderColor: 'gray.800',
      }}
      _checked={{
        borderWidth: '1px',
        borderColor: 'gray.800',
      }}
    >
      <input {...inputProps} aria-labelledby={id} />
      <Box sx={styles} {...checkboxProps} {...rest}>
        <Stack direction="row">
          <Box flex="1">{children}</Box>
          {state.isChecked ? (
            <Circle bg="gray.800" size="6">
              <Icon as={HiCheck} boxSize="4" color="white" />
            </Circle>
          ) : (
            <Circle borderWidth="2px" size="6" />
          )}
        </Stack>
      </Box>
    </Box>
  );
};
