import {
  Alert,
  AlertIcon,
  Button,
  Card,
  CardBody,
  Container,
  Divider,
  Heading,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Skeleton,
  SkeletonText,
  Stack,
  Text,
  Tooltip,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import {
  AircraftType,
  CargoType,
  DimensionUnit,
  QuoteStage,
  QuoteStatus,
  RequestStatus,
  WeightUnit,
} from '@pelicargo/types';
import { Show } from '@pelicargo/ui';
import { getPrettyCargoType, join, prettyDate, prettyNumber } from '@pelicargo/utils';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { uniqBy } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import { HiChevronDown } from 'react-icons/hi';
import { HiChevronLeft, HiShare } from 'react-icons/hi2';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import supportIcon from '../../assets/support-icon.svg';
import { Page } from '../../components/Page';
import { QuoteListFilters } from '../../components/QuoteListFilters';
import { QuoteListingModal } from '../../components/QuoteListingModal';
import { QuoteListItem } from '../../components/QuoteListItem';
import { ENV } from '../../config/env';
import { trpc } from '../../config/trpc';
import { useAuth } from '../../hooks/auth/useAuth';
import { useQuotes } from '../../hooks/quotes/useQuotes';
import { useRequest } from '../../hooks/request/useRequest';
import { useFrontChatBoot } from '../../hooks/useFrontChatBoot';
import { QuoteFilters, useQuoteFilters } from '../../hooks/useQuoteFilters';
import { API } from '../../utils/apiTypes';
import { CONTAINER_2XL } from '../../utils/constants';

type QuoteWithTotalCost = API.QuoteWithTotalCost;
type QuoteStatusType = API.QuoteWithTotalCost['status'];
type Params = {
  requestId?: string;
};

export const QuoteListing = () => {
  const toast = useToast();
  const navigate = useNavigate();
  const params: Params = useParams();
  const { state } = useLocation();
  const requestDetailsModal = useDisclosure();
  const { filters, handleCheckboxFilterChange, handleRadioFilterChange, handleToggleAll } = useQuoteFilters();
  const { initialize } = useFrontChatBoot(document.body);
  const { user } = useAuth();

  const [request, isLoading, error] = useRequest(Number(params?.requestId), state?.request);
  const [isDownloading, setIsDownloading] = useState(false);
  const { quotes, error: quotesError } = useQuotes(Number(params?.requestId));
  const { data: frontSecret } = trpc.getFrontSecret.useQuery();
  const { mutateAsync: sendCustomerQuoteUpdate, isLoading: isEmailSending } =
    trpc.sendCustomerQuoteUpdate.useMutation();

  const origins = useMemo(() => {
    const airportIds = request?.origin_airports?.map((airport) => airport?.airport_as_origin_id);
    return airportIds;
  }, [request?.origin_airports]);

  const destinations = useMemo(() => {
    return request?.destination_airports?.map((airport) => airport?.airport_as_destination_id);
  }, [request?.destination_airports]);

  const airlines = useMemo(() => {
    const allAirlines = quotes?.map((quote) => ({
      label: quote?.airline?.name,
      value: quote?.airline?.id,
    }));

    return uniqBy(allAirlines, 'value');
  }, [quotes]);

  const filterQuotesByAirline = (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
    if (!filters.airlines) {
      return quotes;
    }
    return quotes.filter((quote) => filters.airlines.includes(quote.airline_id));
  };

  const filterQuotesByAirport = (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
    if (!filters.origins || !filters.destinations) {
      return quotes;
    }
    let filteredQuotesByAirport = quotes;

    filteredQuotesByAirport = filteredQuotesByAirport.filter((quote) =>
      filters.origins.includes(quote.origin_airport_id),
    );

    filteredQuotesByAirport = filteredQuotesByAirport.filter((quote) =>
      filters.destinations.includes(quote.destination_airport_id),
    );

    return filteredQuotesByAirport;
  };

  const filterQuotesByStops = (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
    switch (filters.stops) {
      case 'any':
        return quotes;
      case 'nonstop':
        return quotes.filter((quote) => quote.flight_path.length === 1);
      default:
        break;
    }
  };

  const filterQuotesByAircraft = (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
    switch (filters.aircraft) {
      case 'any':
        return quotes;
      case AircraftType.RFS:
        return quotes.filter((quote) =>
          quote?.flight_path?.some((flight) => flight.aircraft_type === AircraftType.RFS),
        );
      default:
        break;
    }
  };

  const filterQuotesByServiceType = (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
    return quotes?.filter((quote) => quote?.quote_variants?.some((variant) => variant.type === filters.serviceType));
  };

  const fastestQuoteSort = useCallback((quotes: QuoteWithTotalCost[]) => {
    if (!quotes.length) return [];

    const priorityTypes: QuoteStatusType[] = [QuoteStatus.Confirmed, QuoteStatus.InProgress];
    const sortedQuotes = quotes
      .filter((quote) => priorityTypes.includes(quote.status))
      .map((quote) => {
        let earliestDelivery = Number(quote.estimated_transit_time[0]);
        if (isNaN(earliestDelivery)) {
          earliestDelivery = Infinity;
        }

        quote.earliestDelivery = earliestDelivery;

        return quote;
      })
      .sort((a, b) => (a.earliestDelivery || 0) - (b.earliestDelivery || 0));

    const expiredOrPendingQuotes = quotes.filter((quote) => !priorityTypes.includes(quote.status));
    return [...sortedQuotes, ...expiredOrPendingQuotes];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterQuotesBySort = useCallback(
    (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
      const filteredQuotes = [...(quotes || [])];

      // quotes provided by useQuotes is always sorted by cheapest
      if (filters.sort === 'Cheapest') {
        return filteredQuotes;
      } else if (filters.sort === 'Fastest') {
        return fastestQuoteSort(filteredQuotes);
      }
    },
    [fastestQuoteSort],
  );

  const filterQuotes = useCallback(
    (quotes: API.QuoteWithTotalCost[], filters: QuoteFilters) => {
      let filteredQuotes = filterQuotesByAirline(quotes, filters);
      filteredQuotes = filterQuotesByAirport(filteredQuotes, filters);
      filteredQuotes = filterQuotesByStops(filteredQuotes, filters);
      filteredQuotes = filterQuotesByAircraft(filteredQuotes, filters);
      filteredQuotes = filterQuotesByServiceType(filteredQuotes, filters);
      filteredQuotes = filterQuotesBySort(filteredQuotes, filters);
      return filteredQuotes;
    },
    [filterQuotesBySort],
  );

  const visibleQuotes = useMemo(() => {
    const filteredQuotes = filterQuotes(quotes, filters);
    return filteredQuotes?.filter((quote) => {
      const hasStatus = quote?.status;
      const hasStatusThatIsNotReadyForDetails = [
        QuoteStatus.Pending,
        QuoteStatus.ReadyForQC,
        QuoteStatus.SentToCarrier,
      ].includes(quote?.status as QuoteStatus);

      return hasStatus && !hasStatusThatIsNotReadyForDetails;
    });
  }, [quotes, filters, filterQuotes]);

  const stage1Quotes = useMemo(() => {
    return quotes?.filter((quote) => quote.stage === QuoteStage.ONE);
  }, [quotes]);

  const stage0Quotes = useMemo(() => {
    return quotes?.filter((quote) => quote.stage === QuoteStage.ZERO);
  }, [quotes]);

  const stage2Quotes = useMemo(() => {
    return visibleQuotes?.filter((quote) => quote.stage === QuoteStage.TWO);
  }, [visibleQuotes]);

  const handleSupport = () => {
    if (!initialize || !frontSecret) {
      return;
    }

    initialize({
      chatId: ENV.FRONT_CHAT_ID,
      token: frontSecret,
      email: user?.email,
      name: user?.full_name,
      requestId: params?.requestId,
      useDefaultLauncher: true,
    });
  };

  const handleDownloadPdf = async () => {
    const inputElement = document.getElementById('root');

    if (!inputElement) return;

    setIsDownloading(true);

    // Use html2canvas to capture a snapshot
    html2canvas(inputElement)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');

        // Initialize jsPDF
        const pdf = new jsPDF('p', 'mm', 'a4');

        // Add image to PDF
        const imgProps = pdf.getImageProperties(imgData);
        const pdfWidth = pdf.internal.pageSize.getWidth();
        const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
        pdf.addImage(imgData, 'PNG', 0, 0, pdfWidth, pdfHeight);
        // Save PDF
        pdf.save('snapshot.pdf');
      })
      .finally(() => {
        setIsDownloading(false);
      });
  };

  const handleEmailRequest = useCallback(async () => {
    try {
      await sendCustomerQuoteUpdate({
        requestId: Number(params?.requestId),
      });
      toast({
        title: 'Success',
        description: 'An email has been sent to you successfully.',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: 'Something went wrong',
        description: error?.message,
        status: 'error',
        duration: 6000,
        isClosable: true,
      });
    }
  }, [params?.requestId, sendCustomerQuoteUpdate, toast]);

  const renderQuote = (quote, index) => {
    const isFirst = index === 0;
    const isStage2 = quote.stage === QuoteStage.TWO;

    if (isFirst && isStage2) {
      return <QuoteListItem key={quote?.id} quote={quote} selectedServiceType={filters.serviceType} />;
    }

    return (
      <QuoteListItem key={quote?.id} quote={quote} selectedServiceType={filters.serviceType} isShowingHints={false} />
    );
  };

  const isRequestOpen = useMemo(() => {
    return [RequestStatus.New, RequestStatus.QuotingInProgress, RequestStatus.QuotingPending].includes(
      request?.status as RequestStatus,
    );
  }, [request?.status]);

  if (error || quotesError?.message) {
    return (
      <Page title="Quote" flex="1">
        <Container w="full" maxW="container.xl" py={{ base: '8', lg: '16' }}>
          <Alert status="error">
            <AlertIcon />
            {error || quotesError?.message}
          </Alert>
        </Container>
      </Page>
    );
  }

  return (
    <Page title="Quote" flex="1" pb="20">
      <Container w="full" maxW={CONTAINER_2XL} py={{ base: '8', lg: '10' }} px={{ base: '4', lg: '12' }}>
        <Stack spacing="6">
          <Stack direction="row" align="center" justify="space-between">
            <Button variant="outline" size="sm" leftIcon={<HiChevronLeft />} onClick={() => navigate('/requests')}>
              Requests
            </Button>
            <Stack direction="row" spacing="2">
              <Button
                px="3"
                py="2"
                size="sm"
                variant="ghost"
                leftIcon={<Image src={supportIcon} />}
                onClick={handleSupport}
              >
                Support
              </Button>
              <Menu>
                <MenuButton
                  size="sm"
                  variant="ghost"
                  alignSelf="flex-end"
                  leftIcon={<HiShare />}
                  rightIcon={<HiChevronDown />}
                  as={Button}
                  isLoading={isEmailSending || isDownloading}
                >
                  Share
                </MenuButton>
                <MenuList>
                  <MenuItem onClick={handleDownloadPdf}>Download</MenuItem>
                  <MenuItem onClick={handleEmailRequest}>Email Me</MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Stack>
          <Stack direction="row" spacing={7}>
            <Stack spacing={0}>
              <Text textStyle="captionSmallSemibold" color="gray.600">
                REQUEST#
              </Text>
              <SkeletonText isLoaded={!isLoading} noOfLines={1}>
                <Heading variant="h4">{`R${request?.id}`}</Heading>
              </SkeletonText>
            </Stack>
            <Show if={!!request?.customer_ref}>
              <Stack spacing={0}>
                <Text textStyle="captionSmallSemibold" color="gray.600">
                  YOUR REFERENCE#
                </Text>
                <Heading variant="h4">{request?.customer_ref}</Heading>
              </Stack>
            </Show>
          </Stack>
          <Skeleton borderRadius="md" isLoaded={!isLoading}>
            <Card px="4" py="3">
              <CardBody p="0">
                <Stack spacing="0">
                  <Stack direction="row" align="center" justify="space-between" wrap="wrap">
                    <Stack direction="row" align="center" wrap="wrap" spacing="4">
                      <Text textStyle="bodySmallRegular" color="text.paragraph">
                        {join(origins)} → {join(destinations)}
                      </Text>
                      <Divider orientation="vertical" borderColor="border.light" borderWidth="1px" h="6" />
                      <Text textStyle="bodySmallRegular">{getPrettyCargoType(request?.cargo_type as CargoType)}</Text>
                      <Divider orientation="vertical" borderColor="border.light" borderWidth="1px" h="6" />
                      <Text textStyle="bodySmallRegular">
                        {request?.cargo?.length === 1
                          ? `${request?.cargo?.length} Piece`
                          : `${request?.cargo?.length} Pieces`}{' '}
                      </Text>
                      <Divider orientation="vertical" borderColor="border.light" borderWidth="1px" h="6" />
                      <Text textStyle="bodySmallRegular">{`Ch. Weight ${prettyNumber(request?.chargeable_weight)} kg`}</Text>
                      <Show if={request?.is_known_shipper}>
                        <Divider orientation="vertical" borderColor="border.light" borderWidth="1px" h="6" />
                        <Text textStyle="bodySmallRegular">Known Shipper</Text>
                      </Show>
                    </Stack>
                    <Button size="sm" onClick={requestDetailsModal.onOpen}>
                      View Details
                    </Button>
                  </Stack>
                </Stack>
              </CardBody>
            </Card>
          </Skeleton>
          <QuoteListFilters
            filters={filters}
            origins={origins}
            destinations={destinations}
            airlines={airlines}
            handleCheckboxFilterChange={handleCheckboxFilterChange}
            handleRadioFilterChange={handleRadioFilterChange}
            handleToggleAll={handleToggleAll}
          />
          <Divider />

          <Show if={isRequestOpen}>
            <Alert variant="top-accent" status="info" justifyContent="center">
              <AlertIcon />
              Competitive quotes stream in live over time! You will receive an email when quoting is complete.
            </Alert>
          </Show>

          <Stack spacing="8">
            <Show if={!quotes?.length}>
              <Stack spacing="4">
                {Array.from({ length: 8 }).map((_, index) => (
                  <Card key={index}>
                    <CardBody>
                      <Stack direction="row" align="center" justify="space-between">
                        <SkeletonText isLoaded={false} noOfLines={1} height="8">
                          <Text>Fake Copy</Text>
                        </SkeletonText>
                        <SkeletonText isLoaded={false} noOfLines={1} height="8">
                          <Text>Fake Copy</Text>
                        </SkeletonText>
                        <SkeletonText isLoaded={false} noOfLines={1} height="8">
                          <Text>Fake Copy</Text>
                        </SkeletonText>
                      </Stack>
                    </CardBody>
                  </Card>
                ))}
              </Stack>
            </Show>
            <Stack spacing="8">
              <Show if={!!stage2Quotes.length}>
                <Stack spacing="2">{stage2Quotes?.map(renderQuote)}</Stack>
              </Show>
              <Show if={!!stage1Quotes.length}>
                <Stack>
                  <Heading as="h6" variant="h6">
                    Quoting
                  </Heading>
                  <Stack spacing="2">{stage1Quotes?.map(renderQuote)}</Stack>
                </Stack>
              </Show>
            </Stack>
            <Show if={!!stage0Quotes.length}>
              <Stack direction="row" align="center" justify="center" wrap="wrap" spacing="4">
                {stage0Quotes?.map((quote) => (
                  <Tooltip key={quote?.id} label={quote?.airline?.name}>
                    <Image src={quote?.airline?.logo_uri} h="52px" w="52px" />
                  </Tooltip>
                ))}
              </Stack>
            </Show>
          </Stack>
        </Stack>
      </Container>

      <QuoteListingModal
        isOpen={requestDetailsModal.isOpen}
        onClose={requestDetailsModal.onClose}
        origin={join(origins)}
        commodity={request?.commodity}
        destination={join(destinations)}
        cargo={request?.cargo}
        dropOffBy={prettyDate(request?.dropoff_by)}
        cargoType={request?.cargo_type as CargoType}
        grossWeight={request?.gross_weight}
        volumetricWeight={request?.volumetric_weight}
        isKnownShipper={request?.is_known_shipper}
        dimensionUnit={request?.original_dimension_unit as DimensionUnit}
        weightUnit={request?.original_weight_unit as WeightUnit}
        request={request}
      />
    </Page>
  );
};
