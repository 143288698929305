import { DimensionUnit, LineItem, LineItemType, RequireOnly, WeightUnit } from '@pelicargo/types';

import { modelUtils } from '.';
import { Units } from './constants';

export type CalculateOptions = {
  ceil?: boolean;
};

const defaultOptions: CalculateOptions = { ceil: false };

// Volumetric Weight

type CargoParams = {
  length: number;
  width: number;
  height: number;
  quantity: number;
  weight?: number;
};

export const getVolumetricWeight = ({ length, width, height, quantity }: CargoParams, options?: CalculateOptions) => {
  const { ceil } = { ...defaultOptions, ...options };
  const volume = length * width * height;
  const volumetricWeight = volume / 6000;
  const totalVolumetricWeight = volumetricWeight * quantity;

  if (ceil) return Math.ceil(totalVolumetricWeight);
  return totalVolumetricWeight;
};

export const getVolumeCBM = ({ length, width, height, quantity }: CargoParams, options?: CalculateOptions) => {
  const { ceil } = { ...defaultOptions, ...options };

  const volumeInCm = length * width * height * quantity;
  const volumeInM = volumeInCm * 1e-6;

  if (ceil) return Math.ceil(volumeInM);
  return volumeInM;
};

// Gross Weight

type GrossWeightParams = CargoParams & { weight?: number };

export const getGrossWeight = ({ quantity, weight }: GrossWeightParams, options?: CalculateOptions) => {
  const { ceil } = { ...defaultOptions, ...options };

  const grossWeight = quantity * (weight || 0);

  if (ceil) return Math.ceil(grossWeight);
  return grossWeight;
};

export const lbToKg = (lb: number) => lb * 0.45359237;
export const kgToLb = (kg: number) => kg * 2.20462262185;
export const cmToIn = (cm: number) => cm * 0.393700787;
export const inchToCm = (inch: number) => inch * 2.54;

export const ensureMetricCargo = (cargo: CargoParams, { weightUnit, dimensionUnit }: Units) => {
  const { weight, length, width, height } = cargo;

  const nextCargo = { ...cargo };

  if (weight) {
    const isKg = weightUnit === WeightUnit.kg;
    if (!isKg) nextCargo.weight = lbToKg(weight);
  }

  const isCm = dimensionUnit === DimensionUnit.cm;
  if (!isCm) {
    nextCargo.length = inchToCm(length);
    nextCargo.width = inchToCm(width);
    nextCargo.height = inchToCm(height);
  }

  // If we originally had the cargo weight as null, we want to keep it null instead of NaN
  if (cargo?.weight === null) nextCargo.weight = undefined;

  return nextCargo;
};

export const effectiveRate = (
  lineItems: RequireOnly<LineItem, 'chargeable_weight' | 'cost' | 'per_unit_value' | 'type'>[],
  cw: number,
) => {
  const airFreight = lineItems.find((item) => modelUtils.lineItem.isRateCategory(item.type));
  const cost = airFreight?.cost || 0;

  if (!airFreight?.per_unit_value) return cost / cw;

  return airFreight.per_unit_value;
};

export const totalCost = (lineItems: RequireOnly<LineItem, 'cost'>[]) => {
  return lineItems.reduce((acc, item) => {
    const cost = item.cost || 0;

    return acc + cost;
  }, 0);
};

const EXCLUSIVE_VARIABLE_RATE_LINE_ITEM_TYPES: LineItemType[] = [
  LineItemType.TARIFF,
  LineItemType.CONTRACT,
  LineItemType.PROMO,
  LineItemType.SPOT,
  LineItemType.FUEL,
  LineItemType.SECURITY,
  LineItemType.TRUCKING,
  LineItemType.NON_STACKABLE,
  LineItemType.FP_SERVICE,
  LineItemType.OTHER_MARKUP,
];

export const exclusiveVariableRate = (lineItems: RequireOnly<LineItem, 'type' | 'cost'>[], cw: number) => {
  const lineItemsToInclude = lineItems.filter((item) => {
    return EXCLUSIVE_VARIABLE_RATE_LINE_ITEM_TYPES.includes(item.type);
  });

  const cost = totalCost(lineItemsToInclude);
  return cost / cw;
};

const ALL_IN_VARIABLE_RATE_LINE_ITEM_TYPES: LineItemType[] = [
  ...EXCLUSIVE_VARIABLE_RATE_LINE_ITEM_TYPES,
  LineItemType.SCREENING,
];

export const allInVariableRate = (lineItems: RequireOnly<LineItem, 'type' | 'cost'>[], cw: number) => {
  const lineItemsToInclude = lineItems.filter((item) => {
    return ALL_IN_VARIABLE_RATE_LINE_ITEM_TYPES.includes(item.type);
  });

  const cost = totalCost(lineItemsToInclude);
  return cost / cw;
};

export const screeningFee = (lineItems: RequireOnly<LineItem, 'cost' | 'type'>[]) => {
  const screening = lineItems.find((item) => item.type === LineItemType.SCREENING);

  return screening?.per_unit_value || screening?.cost;
};
